<template>
  <div>
    <img
      src="@/assets/img/screen/Blizzert_ticket_info_KARAOKE_text_layer.png"
      alt="Karaoke"
      class="karaoke-layer blink"
    />
    <img
      src="@/assets/img/screen/Blizzert_ticket_info_KARAOKE_banner_layer.png"
      alt="Karaoke"
      class="karaoke-layer fade-in"
    />
  </div>
</template>


<script>
export default {
  name: "KaraokeLayer",
};
</script>

<style lang="scss" scoped>
.karaoke-layer {
  position: absolute;
  top: 0;
  left: 0;
}

.blink {
  opacity: 0;
  animation: blink 3s ease-in-out 2s infinite;
}

.fade-in {
  opacity: 0;
  animation: fade-in 1s ease-in-out 2s forwards;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  45% {
    opacity: 1;
  }
  55% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>