<template>
  <div class="wrapper">
    <img
      src="@/assets/img/screen/Blizzert_ticket_info_background.jpg"
      alt="Ticket"
      class="ticket-background"
    />
    <KaraokeLayer v-if="request.karaoke" />
    <div class="photo-wrapper">
      <el-image
        v-show="showImage"
        :src="image"
        class="requestImage"
        fit="contain"
        :z-index="-1"
        @load="showImage = true"
      />
    </div>
  </div>
</template>

<script>
import { ElImage } from "element-plus";
import KaraokeLayer from "./KaraokeLayer.vue";
export default {
  name: "RequestPhoto",
  components: {
    ElImage,
    KaraokeLayer,
  },
  props: {
    image: {
      type: String,
    },
    request: {
      type: Object,
    },
  },
  data() {
    return {
      showImage: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 1920px;
  height: 1080px;
  position: fixed;
  top: 0;
}
.ticket-background {
  position: absolute;
}
.requestImage {
  width: 100%;
  height: 100%;
}
.photo-wrapper {
  padding: 3rem 18rem;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
</style>
