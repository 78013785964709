<template>
  <div class="wrapper">
    <img
      src="@/assets/img/screen/Blizzert_ticket_info_background.jpg"
      alt="Ticket"
      class="ticket-background"
    />
    <div class="vote-results">
      <div
        class="option-wrapper"
        v-for="option in activeVote.options"
        :key="option._id"
      >
        <el-image
          class="option-image"
          :src="`${$store.getters.getConstant('API_URI')}/${option.image_url}`"
          fit="contain"
        >
          <template #error>
            <div class="option-image option-image--error">
              {{ option.name }}
            </div>
          </template>
        </el-image>
        <div
          class="option-meter"
          :style="{ height: parsedPercentage(option.count) }"
        >
          {{ parsedPercentage(option.count) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ElImage } from "element-plus";
export default {
  name: "VoteResults",
  components: {
    ElImage,
  },
  props: {
    activeVote: {
      type: Object,
    },
  },
  computed: {
    totalVotes() {
      return this.activeVote?.options?.reduce((prev, cur) => {
        return prev + cur.count;
      }, 0);
    },
    parsedPercentage() {
      return (count) => `${Math.round((count / this.totalVotes) * 100 || 0)}%`;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 1920px;
  height: 1080px;
  position: fixed;
  top: 0;
}
.ticket-background {
  position: absolute;
}
.vote-results {
  padding: 3rem 18rem 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: space-around;
}

.option {
  &-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &-image {
    width: 18rem;
    height: 18rem;
    overflow: visible;

    &--error {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      font-family: Futura;
      font-size: 4rem;
      text-align: center;
      color: $color-black;
    }
  }

  &-meter {
    min-height: 5rem;
    background-color: rgba(175, 0, 0, 0.5);
    margin-top: 2rem;
    transition: all 0.4s ease-in-out;
    max-height: calc(100% - 20rem);
    color: $color-black;
    font-size: 3.5rem;
    text-align: center;
    letter-spacing: 2px;
    font-family: BebasNeue;
  }
}
</style>
