<template>
  <img
    src="@/assets/img/screen/Blizzert_ticket.png"
    alt="Ticket"
    class="request-accepted"
  />
</template>

<style lang="scss" scoped>
.request-accepted {
  position: fixed;
  object-fit: none;
  object-position: 0 -8rem;
  height: 100%;
  width: 100%;
}
</style>
