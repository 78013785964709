<template>
  <div class="base-screen">
    <img
      src="@/assets/img/screen/Blizzert_ticket_start.png"
      alt="Logo"
      class="base-image"
    />
  </div>
</template>

<style lang="scss" scoped>
.base-screen {
  // width: 100%;
  // height: 100%;
  width: 1920px;
  height: 1080px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
}

.base-image {
  width: 1920px;
  height: 1080px;
}
</style>
