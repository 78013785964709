<template>
  <div class="request-ticket">
    <KaraokeLayer v-if="request.karaoke" />
    <div class="text-wrapper">
      <div class="text-title">Titel</div>
      <div class="text-subtitle">{{ request.song }}</div>
    </div>
    <div class="text-wrapper">
      <div class="text-title">Artiest</div>
      <div class="text-subtitle">{{ request.artist }}</div>
    </div>
    <div class="text-wrapper">
      <div class="text-title">Aanvrager</div>
      <div class="text-subtitle">{{ requesterFullName }}</div>
    </div>
  </div>
</template>

<script>
import KaraokeLayer from "./KaraokeLayer.vue";

export default {
  name: "RequestTicket",
  components: {
    KaraokeLayer,
  },
  props: {
    request: {
      type: Object,
    },
  },
  computed: {
    requesterFullName() {
      return `${this.request.requester_firstname} ${this.request.requester_lastname}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.request-ticket {
  background: url("~@/assets/img/screen/Blizzert_ticket_info_background.jpg");
  width: 1920px;
  height: 1080px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 4rem 0;
  position: fixed;
  top: 0;
  color: $color-black;
}

.text {
  &-wrapper {
    text-align: center;
    text-transform: uppercase;
    max-width: 1475px;
  }
  &-title {
    font-size: 7rem;
    font-family: BebasNeue;
    margin-bottom: -2.8rem;
    letter-spacing: 3px;
    color: #777;
  }
  &-subtitle {
    font-family: NulShock;
    font-size: 10rem;
    // letter-spacing: 3px;
    line-height: 1;
    word-break: break-word;
  }
}
</style>
