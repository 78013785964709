<template>
  <div id="screen-portal">
    <VoteClock :vote="activeVote" :show="showVoteTimer" type="screen" />
    <div v-show="false">
      <img src="@/assets/img/screen/Blizzert_ticket.png" alt="EmptyTcket" />
      <img
        src="@/assets/img/screen/Blizzert_ticket_info_background.jpg"
        alt="TicketBackground"
      />
    </div>
    <transition name="slide-down" :mode="showBaseScreen ? 'out-in' : ''">
      <component :is="getComponent" v-bind="currentProperties"></component>
    </transition>
  </div>
</template>

<script>
import { io } from "socket.io-client";

import fetchMixins from "../mixins/ajax";
import RequestTicket from "../components/RequestTicket";
import BaseScreen from "../components/BaseScreen";
import RequestPhoto from "../components/RequestPhoto";
import VoteResults from "../components/VoteResults";
import EmptyTicket from "../components/EmptyTicket";
import VoteClock from "../components/VoteClock";

const SLIDE_INTERVAL = 600;
export default {
  components: {
    BaseScreen,
    RequestTicket,
    RequestPhoto,
    VoteResults,
    EmptyTicket,
    VoteClock,
  },
  data() {
    return {
      screenMode: "request", //possible values are 'request' and 'vote'
      showComponent: {
        emptyTicket: false,
        requestTicket: false,
        requestPhoto: false,
      },
      activeRequest: null,
      requestImage: null,
      showVoteTimer: false,
    };
  },
  mixins: [fetchMixins],
  computed: {
    getComponent() {
      if (this.showBaseScreen) {
        return "BaseScreen";
      }
      if (this.showVoteStatus) {
        return "VoteResults";
      }
      if (this.showrequestTicket) {
        return "RequestTicket";
      }
      if (this.showRequestPhoto) {
        return "RequestPhoto";
      }
      if (this.showEmptyTicket) {
        return "emptyTicket";
      }
      return false;
    },
    currentProperties() {
      if (this.getComponent === "VoteResults") {
        return { activeVote: this.activeVote };
      }
      if (this.getComponent === "RequestTicket") {
        return { request: this.activeRequest };
      }
      if (this.getComponent === "RequestPhoto") {
        return { image: this.requestImage, request: this.activeRequest };
      }
      return null;
    },
    activeVote() {
      return this.$store.getters.activeVote;
    },
    showBaseScreen() {
      return (
        this.screenMode !== "vote" &&
        !this.showComponent.requestTicket &&
        !this.showComponent.emptyTicket &&
        !this.showComponent.requestPhoto
      );
    },
    showVoteStatus() {
      return this.screenMode === "vote" && this.activeVote !== null;
    },
    showEmptyTicket() {
      return (
        this.screenMode === "request" &&
        this.showComponent.emptyTicket &&
        !this.showComponent.requestTicket &&
        !this.showComponent.requestPhoto &&
        this.activeRequest !== null
      );
    },
    showrequestTicket() {
      return (
        this.screenMode === "request" &&
        this.showComponent.requestTicket &&
        !this.showComponent.emptyTicket &&
        !this.showComponent.requestPhoto &&
        this.activeRequest !== null
      );
    },
    showRequestPhoto() {
      return (
        this.screenMode === "request" &&
        this.showComponent.requestPhoto &&
        !this.showComponent.requestTicket &&
        !this.showComponent.emptyTicket &&
        this.activeRequest !== null
      );
    },
  },
  methods: {
    // showComponent props back to initial values
    resetShowComponents() {
      this.showComponent.emptyTicket = false;
      this.showComponent.requestTicket = false;
      this.showComponent.requestPhoto = false;
    },
    getCurrentScreen() {
      return Object.keys(this.showComponent).find((key) => {
        return this.showComponent[key] ? key : false;
      });
    },
    // Check if there is a currently active Vote
    async fetchActiveVote() {
      const response = await this.fetchData(
        null,
        "vote/active",
        "GET",
        null,
        null
      );

      if (response.statusCode === 200) {
        this.$store.commit("setActiveVote", response.data);
        this.showVoteTimer = true;
      }
    },
    // Check if there is a request with state 'screen'
    async fetchAcceptedRequest() {
      const response = await this.fetchData(
        null,
        "request/screen",
        "GET",
        null,
        null
      );

      if (response.statusCode === 200) {
        this.activeRequest = response.data;
        this.resetShowComponents();
        // this.showComponent.emptyTicket = true;
      }
    },
    // Increase value of activeVote option with specified id
    incrementVoteCounter(id) {
      this.$store.commit("incrementVoteOption", { id });
    },
  },
  watch: {
    async activeRequest(newVal) {
      if (!newVal) {
        return;
      }
      // Pre-fetch the image if necessary
      if (newVal.showimage) {
        const response = await fetch(
          `${this.$store.getters.getConstant("API_URI")}/${newVal.image_url}`
        )
          .then((res) => {
            if (!res.ok) {
              throw new Error(`${res.status} - ${res.statusText}`);
            }
            return res.blob();
          })
          .then((blob) => {
            return URL.createObjectURL(blob);
          })
          .catch(() => {
            this.io.emit("screen-image-error");
          });

        this.requestImage = response || null;
      }
    },
    requestImage(newVal, oldVal) {
      if (oldVal) {
        URL.revokeObjectURL(oldVal);
      }
      if (newVal) {
        this.io.emit("screen-image-ready");
      }
    },
  },
  beforeCreate() {
    const ROOT = this.$store.getters.getConstant("SOCKET_IO_ROOT");
    const PATH = this.$store.getters.getConstant("SOCKET_IO_PATH");
    this.io = io(ROOT, {
      path: PATH,
    });
    this.io.on("determineOffset", () => {}); // A first event is needed to make recovery work
  },
  created() {
    this.fetchActiveVote();
    this.fetchAcceptedRequest();

    // Emit screen-change, so admin-panel knows ScreenPortal is reloaded
    this.io.emit("screen-change", "screen-portal-reloaded");
  },
  mounted() {
    this.io.on("dashboard-reloaded", () => {
      // Broadcast the state of the requestImage
      const imageEvent =
        this.requestImage === null
          ? "screen-image-error"
          : "screen-image-ready";

      this.io.emit(imageEvent);

      // Broadcast the current screen state
      let screenState = "Startscherm";
      if (this.showrequestTicket) screenState = "Onthullen";
      if (this.showRequestPhoto) screenState = "Foto";
      this.io.emit("screen-change", screenState);
    });

    this.io.on("screen-change", (screen) => {
      if (["request", "vote"].indexOf(screen) > -1) {
        const currentScreen = this.getCurrentScreen();
        if (screen === "request") {
          this.screenMode = screen;

          if (currentScreen) {
            this.resetShowComponents();
            this.showComponent.emptyTicket = true;
            setTimeout(() => {
              this.resetShowComponents();
              this.showComponent[currentScreen] = true;
            }, SLIDE_INTERVAL);
          }
        }

        if (screen === "vote") {
          if (currentScreen) {
            this.resetShowComponents();
            this.showComponent.emptyTicket = true;
            setTimeout(() => {
              this.resetShowComponents();
              this.screenMode = "vote";
              this.showComponent[currentScreen] = true;
            }, SLIDE_INTERVAL);
          } else {
            this.screenMode = "vote";
          }
        }
      }
    });

    this.io.on("vote-start", (payload) => {
      this.$store.commit("setActiveVote", payload.vote);
      this.showVoteTimer = true;
    });

    this.io.on("vote-stop", () => {
      this.showVoteTimer = false;
    });

    this.io.on("vote-add", (payload) => {
      this.incrementVoteCounter(payload.id);
    });

    this.io.on("request-state-change", ({ request, state }) => {
      if (state === "screen") {
        this.resetShowComponents();
        this.activeRequest = request;
      }
    });
    this.io.on("screen-reveal-request", (value) => {
      const fromBaseScreen = this.showBaseScreen;
      this.resetShowComponents();

      if (fromBaseScreen) {
        switch (value) {
          case "Foto":
            return (this.showComponent.requestPhoto = true);
          case "Onthullen":
            return (this.showComponent.requestTicket = true);
        }
      }

      if (value === "Foto") {
        this.showComponent.emptyTicket = true;
        setTimeout(() => {
          this.resetShowComponents();
          this.showComponent.requestPhoto = true;
        }, SLIDE_INTERVAL);
      } else if (value === "Onthullen") {
        this.showComponent.emptyTicket = true;
        setTimeout(() => {
          this.resetShowComponents();
          this.showComponent.requestTicket = true;
        }, SLIDE_INTERVAL);
      }
    });
    this.io.on("screen-clear-request", (value) => {
      this.resetShowComponents();
      if (value === "Startscherm") {
        this.resetShowComponents();
      } else {
        // the request was 'deleted'
        this.resetShowComponents();
        this.activeRequest = null;
      }
    });
  },
  unmounted() {
    this.io.off();
  },
};
</script>

<style lang="scss" scoped>
#screen-portal {
  background: url("~@/assets/img/screen/Blizzert_ticket_start_background.jpg");
  width: 1920px;
  height: 1080px;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
}

.slide-down-enter-active,
.slide-down-leave-active {
  transition: all 0.6s linear;
}

.slide-down-enter-from {
  transform: translateY(-100%);
}

.slide-down-leave-to {
  transform: translateY(100%);
}
</style>
